import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Landing from "../../components/landingSection";
import icons from "../../DB/icon_list_DV";
import ImageRight from "../../components/imageRight";
import ImageLeft from "../../components/imageLeft";
import Image from "../../components/imageProcessing";
import Icon_list from "../../components/SSL_COMP/icon_list";
import Cases from "../../DB/ev-cases";
import Case from "../../components/SSL_COMP/featureBlock";
// import Packages from "../../DB/plan-card-ev";
import PlanCard from "../../components/plan_card";
import LI from "../../components/plan-li";
import Feature from "../../components/SSL_COMP/FeaturesWithoutIcon";
import TrustedBrands from "../../components/SSL_COMP/trustedbrands";
import NavBar from "../../components/SSL_COMP/SSL-nav-bar";
/* eslint react/prop-types: 0 */
import { graphql, useStaticQuery } from "gatsby";

function DV() {
	const data = useStaticQuery(graphql`
		query EVQuery {
			allProducts(filter: { productID: { in: [9, 13, 14] } }) {
				nodes {
					productID
					productGroupID
					name
					description
					annually
				}
			}
		}
	`);
	return (
		<Layout>
			<SEO
				keywords={[
					`extended validation ssl`,
					`do i need an ev certificate`,
					`extended validation ssl certificate`,
					`extended ssl`,
					"extended validation ssl certificates",
					"multi domain ev ssl",
					"extended validation certificate benefits",
					"cheapest ev ssl",
					"extended validation certificate cost",
					"free ev ssl trial",
					"ev ssl cheap",
					"best ev ssl certificate",
					"ev ssl certificate",
					"ev certificate",
					"ev ssl",
					"certificate with extended validation",
					"extended validation ssl certificate vs standard",
					"sites with ssl-ev protection",
					"ssl green bar",
					"green bar ss",
				]}
				title="Extended Validation SSL - SSL Certificates"
				lang="en"
				image="hostylus-card-preview.jpg"
				description="EVC is the highest level of SSL giving not only protection but also an extra layer of trust to users thanks to its green bar feature that gives you more credit."
			/>

			<Landing
				image={<Image image={"sslLanding.png"} alt={"ssl certificates"} />}
				header={"Extended Validation SSL"}
				sub_header={
					"Activate the green address bar for the highest trust and conversions"
				}
				btnText={"pricing"}
				btnURL={"#pricing"}
				hideSecondary_btn={"hidden"}
			/>
			<NavBar />

			<section className="w-full py-10">
				<ImageRight
					header={"What is Extended Validation SSL?"}
					desc={
						<>
							<p>
								EV SSL is an Extended Validation Certificate, the highest class
								of SSL available today and gives more credibility and trust to
								your website compared to using an organization or domain
								validated SSL Certificate.
							</p>
							<p>
								Extended Validation SSL will in most cases display your company
								or site name in green. This prominent visual verification
								provides visitors added confidence that steps were taken to
								confirm the identity of the website and business they&apos;re
								visiting, increasing user trust in your website and its
								credibility – this is why most large companies and organizations
								choose EV certificates.
							</p>
						</>
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					hideLearnMore={"hidden"}
					image={
						<div className="shadow-xl w-max mx-auto mobile:w-4/5">
							<Image
								image={"ssl/secure.svg"}
								alt={"secured green bar lock"}
								className="shadow-2xl"
							/>
						</div>
					}
					btnLink="#"
				/>
			</section>
			<section className="features w-3/5 px-40 mobile:px-12 mobile:w-full">
				<h2 className="text-3xl text-black font-bold  capitalize mobile:text-center">
					Recommended Use Cases
				</h2>
				<div className="features  grid grid-cols-3 gap-3 flex justify-items-stretch  mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:px-16 py-4 laptop:px-6">
					{Cases.map((feature, key) => (
						<Case
							key={key}
							image={feature.image}
							header={feature.title}
							alt={feature.imageAlt}
						/>
					))}
				</div>
			</section>
			<section className="w-full p-20 my-20 mobile:p-10">
				<div className=" mx-auto grid grid-cols-3 mobile:grid-cols-1">
					<Feature
						header={"Prominent Visual Identity"}
						desc={
							"Increase trust and confidence in your website with full identity verification and prominent visual features."
						}
					/>
					<Feature
						header={"Trust Site Seal"}
						desc={
							"Our SSL Certificates come with a trust seal that has been proven to increase visitor confidence and customer conversions."
						}
					/>
					<Feature
						header={"$1.5m Warranty"}
						desc={
							"EV Certificates come with a $1.5m warranty that covers data breaches caused due to a certificate flaw."
						}
					/>
				</div>
			</section>
			<section className="w-full p-10 bg-gradient-to-r from-blue-600 via-blue-700 to-blue-900 my-20 mobile:p-10">
				<section
					id="pricing"
					className="max-w-5xl mx-auto my-16 mobile:pb-3 tablet:mx-5 "
				>
					<h2 className="text-4xl text-white font-bold text-center">
						Certificate Pricing
					</h2>
					<div className="grid grid-cols-3 gap-6 flex justify-items-stretch mt-6 max-w-4xl mx-auto mobile:grid-cols-1 tablet:grid-cols-2 tablet:gab-2 mobile:justify-items-center py-8">
						{data.allProducts.nodes.map(function (items, key) {
							var stripedHtml = items.description.replace(/<[^>]+>/g, "");
							var description = stripedHtml.split("\n");
							return (
								<PlanCard
									key={key}
									price={items.annually}
									header={items.name}
									productID={items.productID}
									perWhat={"yr"}
									myItems={
										<ul
											style={{
												listStyleImage:
													"url('https://ik.imagekit.io/softylus/tick_PLS8tkQNa.jpg')",
												listStylePosition: "inside",
											}}
											className="list-disc"
										>
											{description.slice(1).map((listItem, listIndex) => (
												<LI
													key={listIndex}
													LI={listItem}
													textSize={"text-sm"}
												/>
											))}
										</ul>
									}
								/>
							);
						})}
					</div>
				</section>
			</section>
			<section className="w-full py-10">
				<ImageLeft
					header={"Contains your EV Authenticated Organization Details"}
					desc={
						"Certificate details indicate your website is using an Extended Validated SSL Certificate and include the issuing CA, validity status and expiration date."
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					hideLearnMore={"hidden"}
					image={
						<div className="shadow-xl w-max mx-auto">
							<Image
								image={"cert-details-ov.png"}
								alt={"certificate details example"}
								className="shadow-2xl"
							/>
						</div>
					}
				/>
			</section>

			<section className="mx-auto text-center py-10">
				<div>
					<p className="text-3xl font-bold w-full px-10">
						There&apos;s also a lot more reasons why you should consider adding
						SSL to your website:
					</p>
				</div>
				<Icon_list icons={icons} columns={"4"} />
			</section>
			<TrustedBrands />
		</Layout>
	);
}

export default DV;
