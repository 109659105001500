export default [
  {
    image: "ssl/Business-critical.svg",
    title: "Business-critical domains",
    imageAlt: "Business-critical domains",
  },
  {
    image: "ssl/ecommerce.svg",
    title: "eCommerce",
    imageAlt: "eCommerce",
  },
  {
    image: "ssl/new-account.svg",
    title: "New account signup pages",
    imageAlt: "New account signup pages",
  },
];
